import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { withAuthentication, withAuthorization } from '../_auth';
import LoginPage from '../LoginPage/LoginPage';
import StoresPage from '../StoresPage/StoresPage';

const condition = authUser => !!authUser && ['admin', 'convertr_admin'].includes(authUser.role);

const AppRouter = () => (
    <Router>
        <div>
            <Switch>
                <Route path="/" exact component={withAuthorization(condition)(StoresPage)} />
                <Route path="/login" component={LoginPage} />
                <Redirect to="/" />
            </Switch>
        </div>
    </Router>
)

export default withAuthentication(AppRouter);