import React from 'react';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Box, Menu, MenuItem } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { withFirebase } from '../_firebase';
import AuthUserContext from '../_auth/context';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#fff!important',
    color: '#000'
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    height: 20
  },
}));

function MainLayout(props) {
  const { children, firebase, title } = props;

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div className={classes.root}>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Box alignItems="center" display="flex" width="100%" flexDirection="row" justifyContent="space-between">
                <img className={classes.logo} src={require('../_assets/img/mobilizei_logo.png')} />
                <Box alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography variant="h6" noWrap>
                    {title}
                  </Typography>
                </Box>
                <div>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem dense disabled onClick={handleClose}>{authUser.email}</MenuItem>
                    <Divider />
                    <MenuItem dense onClick={() => firebase.doSignOut()}>Sair</MenuItem>
                  </Menu>
                </div>
              </Box>
            </Toolbar>
          </AppBar>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {children}
          </main>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
}

export default withFirebase(MainLayout);