import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/material';
import Logo from '../_assets/img/logo.svg'

const useStyles = makeStyles(() => ({
    container: {
        background: `url(${require('../_assets/img/brand-dots.svg').default}) no-repeat -350px 100px`,
        minHeight: '100vh',
        paddingTop: 50,
        "&:before": {
            background: `url(${require('../_assets/img/brand-circle.svg').default}) no-repeat`,
            backgroudOverflow: 'hidden',
            backgroundSize: '100% 100%',
            content: '""',
            height: 500,
            position: "absolute",
            right: -300,
            top: -150,
            width: 500,
            zIndex: -999,
            '@media (max-width: 960px)': {
                right: -400,
                top: -200,
            }
        },
        '@media (max-width: 960px)': {
            background: `url(${require('../_assets/img/brand-dots.svg').default}) no-repeat -450px 200px`,
        }
    },
    logo: {
        position: 'absolute',
        left: 38.5,
        top: 16,
        '@media (max-width: 960px)': {
            left: 16,
            top: 8,
        },
        cursor: "pointer"
    }
}));

export default function PublicLayout({ children }) {
    const classes = useStyles();

    return (
        <Container maxWidth={false} component="main" className={classes.container}>
            {children}
        </Container>
    );
}