import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';

const LinkBehavior = React.forwardRef((props, ref) => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />;
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#3cd568',
        },
        secondary: {
            main: '#000'
        },
        white: {
            main: '#fff'
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "#root": {
                    overflow: 'hidden',
                    position: 'relative'
                },
                a: {
                    color: '#3cd568',
                    cursor: 'pointer',
                    textDecoration: 'none'
                }
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    lineHeight: 1,
                    padding: '12px 30px'
                },
                containedPrimary: {
                    color: '#fff',
                    fontFamily: '"Cabin", sans-serif',
                    fontWeight: 700,
                    textTransform: 'lowercase'
                },
                textPrimary: {
                    textTransform: 'none'
                },
                textSecondary: {
                    textTransform: 'none'
                }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior,
            },
        },
        MuiFormLabel: {
          styleOverrides: {
            root: {
                background: 'white',
                padding: '0 10px'
            }
          }
        },
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            },
            styleOverrides: {
                root: {
                    textDecoration: 'none'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: '"Cabin", sans-serif!important'
                },
                h2: {
                    fontWeight: 'bold'
                }
            }
        }
    }
});

export default theme;