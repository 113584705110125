import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import ReactModal from 'react-modal';
import { Button, CircularProgress, FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import _ from 'lodash';
import { mobilizeiConvertrService } from '../_services/mobilizei/convertr.service';
import { showApiError } from '../_helpers/errors';
import { toast } from 'react-toastify';

ReactModal.setAppElement('#root')

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 10, 10, 0.45)',
        zIndex: 999
    },
    content: {
        height: 'fit-content',
        width: '35%',
        margin: 'auto'
    }
};

const useStyles = makeStyles(() => ({
    title: {
        marginTop: 0
    },
    helpButton: {
        position: 'absolute',
        top: 12,
        right: 0
    },
    fullWidthShrink: {
        width: '133%'
    },
    statusTooltipContainer: {
        marginBottom: 15,
        marginTop: 5
    }
}))

export default function AddStoreModal({ isOpen, onClose, onSuccess }) {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        email: '',
        name: '',
        storeId: '',
        storeName: '',
        storeUrl: ''
    })

    const [errors, setErrors] = useState({
        email: null,
        name: null,
        storeId: null,
        storeName: null,
        storeUrl: null
    })

    const handleChange = (e) => {
        let newFormData = { ...formData };
        newFormData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData(newFormData);
    }

    const handleSave = () => {
        setLoading(true);

        const errors = {};
        setErrors({});

        const requiredMsg = "Este campo é obrigatório.";

        if (_.isEmpty(formData.email)) {
            errors['email'] = requiredMsg;
        }
        if (_.isEmpty(formData.name)) {
            errors['name'] = requiredMsg;
        }
        if (_.isEmpty(formData.storeId)) {
            errors['storeId'] = requiredMsg;
        }
        if (_.isEmpty(formData.storeName)) {
            errors['storeName'] = requiredMsg;
        }
        if (_.isEmpty(formData.storeUrl)) {
            errors['storeUrl'] = requiredMsg;
        }

        if (_.isEmpty(errors)) {
            const data = {
                email: formData.email,
                name: formData.name,
                storeId: formData.storeId,
                storeName: formData.storeName,
                storeUrl: formData.storeUrl
            }

            mobilizeiConvertrService.createUser(data)
                .then(() => {
                    toast.success('Loja adicionada com sucesso!')
                    onSuccess();
                    onClose();
                })
                .catch((e) => {
                    showApiError(e.error)
                    console.log(e)
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(false);
            setErrors(errors);
        }
    }

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => onClose()}
            style={customStyles}
        >
            <h2 align="center" className={classes.title}>Adicionar nova loja</h2>

            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl} error={!!errors.storeId} fullWidth>
                        <TextField
                            error={!!errors.storeId}
                            fullWidth
                            label="ID da loja"
                            margin="normal"
                            name="storeId"
                            onChange={handleChange}
                            placeholder=""
                            value={formData.storeId || ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {errors.storeId && <FormHelperText>{errors.storeId}</FormHelperText>}
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl className={classes.formControl} error={!!errors.storeName} fullWidth>
                        <TextField
                            error={!!errors.storeName}
                            fullWidth
                            label="Nome da loja"
                            margin="normal"
                            name="storeName"
                            onChange={handleChange}
                            value={formData.storeName || ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {errors.storeName && <FormHelperText>{errors.storeName}</FormHelperText>}
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl className={classes.formControl} error={!!errors.storeUrl} fullWidth>
                        <TextField
                            error={!!errors.storeUrl}
                            fullWidth
                            label="URL da loja"
                            margin="normal"
                            name="storeUrl"
                            onChange={handleChange}
                            value={formData.storeUrl || ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {errors.storeUrl && <FormHelperText>{errors.storeUrl}</FormHelperText>}
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl className={classes.formControl} error={!!errors.email} fullWidth>
                        <TextField
                            error={!!errors.email}
                            fullWidth
                            label="Email"
                            margin="normal"
                            name="email"
                            onChange={handleChange}
                            placeholder=""
                            value={formData.email || ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {errors.email && <FormHelperText>{errors.email}</FormHelperText>}
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl className={classes.formControl} error={!!errors.name} fullWidth>
                        <TextField
                            error={!!errors.name}
                            fullWidth
                            label="Nome do responsável"
                            margin="normal"
                            name="name"
                            onChange={handleChange}
                            value={formData.name || ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {errors.name && <FormHelperText>{errors.name}</FormHelperText>}
                    </FormControl>
                </Grid>

                <Grid container justifyContent="center" item xs={12}>
                    {loading ? <CircularProgress /> :
                        <Button variant="contained" color="primary" onClick={handleSave} sx={{ fontSize: '18px'}}>
                            Salvar
                        </Button>
                    }
                </Grid>
            </Grid>
        </ReactModal>
    )
}