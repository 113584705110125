import { apiFetcher } from "./apiFetcher";

export const mobilizeiConvertrService = {
    createUser,
    getStorePanelUrl
};

function createUser(data) {
    const url = `platforms/convertr/users`

    return apiFetcher(url, {
        method: "POST",
        body: JSON.stringify(data)
    }).then(response => response);
}

function getStorePanelUrl(userId) {
    const url = `platforms/convertr/users/${userId}/panel_url`

    return apiFetcher(url, {
        method: "GET"
    }).then(response => response);
}